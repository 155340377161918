<template>
  <div class="modal-overlay d-flex align-items-center">
    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow" @click.stop>
        <div class="d-flex">
          <h3 class="pt-1 pb-2 text-left text-nowrap w-100">Kontrola PV*Sol</h3>
          <div class="text-right cursor-pointer" @click="$emit('close-modal')">
            <img class="closeModal-img" src="@/assets/close-icon.svg" alt="" />
          </div>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <div :class="getStyle1">
              <div :class="getStyle2">
                <label for="power" class="pr-1">Výkon, kWp</label>
              </div>
              <ValidationProvider rules="required|min_value:1" name="'Výkon'" mode="lazy" v-slot="{ classes, errors }">
                <b-form-input
                  id="power"
                  type="number"
                  min="0"
                  max="10000"
                  size="sm"
                  placeholder="0"
                  v-model="contractData.power"
                  class="d-table-cell w-100px"
                  :title="errors[0]"
                  :state="getState(classes)"
                />
              </ValidationProvider>
            </div>

            <div :class="getStyle1">
              <div :class="getStyle2">
                <label for="numPhasesPVSol" class="pr-1">Faze</label>
              </div>
              <ValidationProvider rules="required" name="'Faze'" mode="lazy" v-slot="{ classes, errors }">
                <b-form-select
                  id="numPhasesPVSol"
                  v-model="contractData.numPhasesPVSol"
                  :options="lists.numPhases"
                  class="form-select form-select-sm w-100px d-inline-block "
                  size="sm"
                  :title="errors[0]"
                  :state="getState(classes)"
                />
              </ValidationProvider>
            </div>

            <div :class="getStyle1">
              <div :class="getStyle2">
                <label for="inverterType" class="pr-1">Střídač - Typ</label>
              </div>
              <b-form-group class="text-left fs-15px w-320px" label-for="inverterType">
                <ValidationProvider :rules="{ required: true, mustBeInList: { list: lists.inverterType }}" name="'Střídač - Typ'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-input
                    id="inverterType"
                    list="inverterTypeList"
                    name="inverterType"
                    class="form-control form-control-sm"
                    v-model="contractData.inverterType"
                    :title="errors[0]"
                    :state="getState(classes) && !validateWithDataFromList('inverterType')"
                  />
                  <span class="invalid-feedback d-block">{{ errors[0] }}</span>
                </ValidationProvider>
                <b-datalist
                  id="inverterTypeList"
                  :options="lists.inverterType"
                  value-field="value"
                  autocomplete="off"
                />
              </b-form-group>
              <div class="d-table-cell w-75px text-left">
                {{inverterCode}}
              </div>
            </div>

            <div :class="getStyle1" class="w-950px">
              <div :class="getStyle2">
                <label for="batteryStorage" class="pr-1">Bateriové úložiště</label>
              </div>
              <div class="">
                <b-form-input
                  id="batteryStorage"
                  name="batteryStorage"
                  class="form-control form-control-sm w-100px d-inline-block"
                  :value="contractData.battery.storage"
                  disabled
                />

                <div class="text-nowrap d-inline-block">
                  <label for="batteryCount" class="pl-3">počet baterie</label>
                </div>
                <ValidationProvider :rules="{required: !contractData.battery.noBattery}" name="'Počet baterie'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                    id="batteryCount"
                    name="batteryCount"
                    v-model="contractData.battery.count"
                    :options="lists.batteryCount"
                    class="form-select form-select-sm w-95px d-inline-block"
                    size="sm"
                    :disabled="contractData.battery.noBattery"
                    :title="errors[0]"
                    :state="getState(classes, contractData.battery.noBattery)"
                  />
                </ValidationProvider>

                <div class="text-nowrap d-inline-block">
                  <label for="batteryCapacity" class="pl-3">kapacita</label>
                </div>
                <ValidationProvider :rules="{required: !contractData.battery.noBattery}" name="'Kapacita'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                    id="batteryCapacity"
                    name="batteryCapacity"
                    v-model="contractData.battery.capacity"
                    :options="lists.batteryCapacity"
                    class="form-select form-select-sm w-110px d-inline-block"
                    size="sm"
                    :disabled="contractData.battery.noBattery"
                    :title="errors[0]"
                    :state="getState(classes, contractData.battery.noBattery)"
                  />
                </ValidationProvider>

                <div class="text-nowrap d-inline-block">
                  <label class="pl-2">({{getPower}} kWh)</label>
                </div>

                <b-form-checkbox
                  id="noBattery"
                  v-model="contractData.battery.noBattery"
                  name="noBattery"
                  class="ml-2 d-inline-block"
                  @change="selectNoBattery"
                >
                  bez bateriového uložiště
                </b-form-checkbox>
              </div>
            </div>
            <div v-for="(MTTP, indxMTTP) in contractData.panels" :key="MTTP.name" :class="getStyle1" class="w-900px mt-2 card mr-2">
              <div class="card-body p-1">
                <h5 class="card-title pl-1 d-inline-block">{{MTTP.name}}</h5>

                <div class="pl-3 d-inline-block align-top">
                  <div v-for="(string, indxString) in MTTP.strings" :key="string.name" class="mh-35px" :class="{'border-top border-light-subtle pt-2': indxString && string.enabled}">
                    <b-form-checkbox
                      :id="string.name"
                      :name="string.name"
                      v-model="string.enabled"
                      class="pl-3 pt-3px d-inline-block align-top"
                      @change="selectString(string)"
                    >
                      <span class="pl-1 fs-15px">{{string.name}}</span>
                    </b-form-checkbox>
                    <div v-if="string.enabled" class="pl-3 d-inline-block align-top w-600px">
                      <div
                        v-for="(type, indxPanel) in string.types" :key="'key'+indxPanel"
                        class="pl-0 text-left fs-15px d-inline-block align-top pb-2 max-h-60px"
                      >
                        <template>
                          <b-icon-plus-circle
                            v-if="!indxPanel && string.types.length < 2"
                            @click="addTypeOfPanel(string)"
                            variant="primary"
                            class="cursor-pointer fs-14px"
                          />
                          <b-icon-plus-circle
                            v-else-if="!indxPanel"
                            variant="secondary"
                            class="cursor-not-allowed fs-14px"
                            disabled="disabled"
                          />
                          <b-icon-x
                            v-else
                            variant="danger"
                            class="cursor-pointer fs-14px"
                            @click="removeTypeFromString(string, indxPanel)"
                          />
                        </template>
                        <label class="pl-1">FV panely - Typ</label>
                        <ValidationProvider :rules="{ required: true, mustBeInList: { list: lists.fvPanelModel }}" name="'FV panely - Typ'" mode="lazy" v-slot="{ classes, errors }">
                          <b-form-input
                            list="panelTypeList"
                            name="panelType"
                            class="form-control form-control-sm d-inline-block w-300px"
                            v-model="contractData.panels[indxMTTP].strings[indxString].types[indxPanel]"
                            :title="errors[0]"
                            :state="getState(classes) && !validatePanelsFromList(
                              contractData.panels[indxMTTP].strings[indxString].types[indxPanel], 'fvPanelModel'
                            )"
                          />
                          <span class="invalid-feedback pl-135px">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <b-datalist
                          id="panelTypeList"
                          :options="lists.fvPanelModel"
                          autocomplete="off"
                        />
                        <label v-if="string.enabled" class="pl-2">
                          {{panelPowerAndCode(contractData.panels[indxMTTP].strings[indxString].types[indxPanel])}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div :class="getStyle1">
              <div :class="getStyle2">
                <label for="areaRoof" class="pr-1">Na střeše objektu bude zabírat plochu cca</label>
              </div>
              <ValidationProvider rules="required|min_value:1" name="'Plocha'" mode="lazy" v-slot="{ classes, errors }">
                <b-form-input
                  id="areaRoof"
                  type="number"
                  min="1"
                  max="10000"
                  size="sm"
                  v-model="contractData.areaRoof"
                  class="d-inline-block w-100px ml-2"
                  placeholder="0"
                  :title="errors[0]"
                  :state="getState(classes)"
                />
              </ValidationProvider>
              <label class="pl-2">m<sup>2</sup></label>
            </div>

            <div v-for="(roof, indxRoof) in contractData.roofs" :key="'roof'+indxRoof" :class="getStyle1" class="w-950px">
              <template>
                <b-icon-plus-circle
                  v-if="!indxRoof && isCanAddRoofs"
                  @click="addRoof()"
                  variant="primary"
                  class="cursor-pointer"
                />
                <b-icon-plus-circle
                  v-else-if="!indxRoof && !isCanAddRoofs"
                  variant="secondary"
                  class="cursor-not-allowed"
                  disabled="disabled"
                />
                <b-icon-x
                  v-else-if="indxRoof"
                  variant="danger"
                  class="cursor-pointer"
                  @click="removeRoof(indxRoof)"
                />
                <span v-else class="pl-3"></span>
              </template>
              <div class="d-table-cell w-50px text-nowrap pr-1 pl-1">
                <label :for="'roof'+indxRoof" class="pr-1">Střecha</label>
              </div>
              <div class="">
                <ValidationProvider rules="required" name="'Střecha'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                    :id="'roof'+indxRoof"
                    v-model="roof.roof"
                    :options="lists.roofs"
                    class="form-select form-select-sm w-100px d-inline-block"
                    size="sm"
                    :title="errors[0]"
                    :state="getState(classes)"
                  />
                </ValidationProvider>
                <label for="gradient" class="pr-1 pl-4">sklon</label>
                <div class="d-inline-block w-75px ml-1">
                  <ValidationProvider rules="required|integer|between:0,99" name="'sklon'" mode="lazy" v-slot="{ classes, errors }">
                    <b-input-group append="°" size="sm" class="app1">
                      <b-form-input
                        :id="'gradient'+indxRoof"
                        size="sm"
                        class="append"
                        placeholder="0"
                        type="number"
                        v-model="roof.gradient"
                        min="0"
                        max="99"
                        :title="errors[0]"
                        :state="getState(classes)"
                      />
                    </b-input-group>
                  </ValidationProvider>
                </div>

                <label for="direction" class="pr-1 pl-4">směr</label>
                <ValidationProvider rules="required" name="'Střešní plocha'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                    :id="'direction'+indxRoof"
                    v-model="roof.direction"
                    :options="lists.directions"
                    class="form-select form-select-sm w-100px d-inline-block"
                    size="sm"
                    :title="errors[0]"
                    :state="getState(classes)"
                  />
                </ValidationProvider>

                <label for="azimuth" class="pr-1 pl-4">azimut</label>
                <div class="d-inline-block w-90px ml-1">
                  <ValidationProvider rules="required|integer|between:0,360" name="'azimut'" mode="lazy" v-slot="{ classes, errors }">
                    <b-input-group append="°" size="sm">
                      <b-form-input
                        :id="'azimuth'+indxRoof"
                        size="sm"
                        class="append"
                        placeholder="0"
                        type="number"
                        min="0"
                        max="360"
                        v-model="roof.azimuth"
                        :title="errors[0]"
                        :state="getState(classes)"
                      />
                    </b-input-group>
                  </ValidationProvider>
                </div>

                <label for="numberOfPanels" class="pr-1 pl-4">počet panelů</label>
                <ValidationProvider rules="required|integer|between:1,100" name="'počet panelů'" v-slot="{ classes, errors }">
                  <b-form-input
                    :id="'numberOfPanels'+indxRoof"
                    type="number"
                    min="1"
                    max="100"
                    size="sm"
                    v-model="roof.numberOfPanels"
                    class="d-inline-block w-75px ml-1"
                    placeholder="0"
                    :title="errors[0]"
                    :state="getState(classes)"
                  />
                </ValidationProvider>
              </div>
            </div>
            <b-button type="submit" class="mt-4 mb-1" variant="primary">Uložit data a zavřít</b-button>
          </b-form>
        </ValidationObserver>
      </div>
    </b-overlay>
  </div>
</template>


<script>
import {MESSAGES} from '@/constants/form.constants'
import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";
import _ from "lodash";

export default {
  props: {
    id: { type: String },
  },
  mixins: [commonMixin],
  computed: {
    MESSAGES() {
      return MESSAGES
    },
    inverterCode() {
      const inverter = this.lists.inverters.find(inverter => inverter.model === this.contractData.inverterType);
      return inverter?.code || '';
    },
    getStyle1() { return 'text-left pt-2 w-550px d-table'; },
    getStyle2() { return 'd-table-cell w-145px text-nowrap'; },
    getPower() {
      let power = this.contractData.battery.count * Number(this.contractData.battery.capacity?.replace(',', '.')) || 0;
      return power ? power?.toFixed(2) : '-';
    },
    isCanAddRoofs() {
      return this.contractData.roofs.length < 5;
    },
  },
  data() {
    const oneToNine = Array(10).fill(0).map((e,i)=>i+1);
    const roofEmpty = {roof: null, gradient: null, direction: null, azimuth: null, numberOfPanels: null};
    return {
      isLoading: false,
      roofEmpty: {...roofEmpty},
      lists: {
        inverterType: [],
        inverters: [],
        batteryCount: oneToNine,
        batteryCapacity: [],
        fvPanels: [],
        fvPanelModel: [],
        roofs: [],
        numPhases: [],
        directions: [],
      },
      contractData: {
        power: null,
        numPhasesPVSol: null,
        inverterType: null,
        areaRoof: null,
        battery: {
          storage: 'Wattsonic TP',
          count: null,
          capacity: null,
          noBattery: false,
        },
        panels: [
          {
            name: 'MTTP1',
            strings: [
              {name: 'String 1.1', num: '1.1', enabled: false, types: []},
              {name: 'String 1.2', num: '1.2', enabled: false, types: []},
            ],
          },
          {
            name: 'MTTP2',
            strings: [
              {name: 'String 2.1', num: '2.1', enabled: false, types: []},
              {name: 'String 2.2', num: '2.2', enabled: false, types: []},
            ],
          }
        ],
        roofs: [
          {...roofEmpty},
        ],
      },
    }
  },
  methods: {
    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },
    addTypeOfPanel(string) {
      this.$set(string.types, string.types.length, '');
    },
    addRoof() {
      this.$set(this.contractData.roofs, this.contractData.roofs.length, {...this.roofEmpty});
    },
    removeRoof(indx) {
      this.contractData.roofs.splice(indx, 1);
    },
    removeTypeFromString(string, indx) {
      return string.types.splice(indx, 1)
    },
    selectNoBattery(checked) {
      this.contractData.battery.noBattery = checked;
      this.contractData.battery.count = null;
      this.contractData.battery.capacity = null;
    },
    selectString(string) {
      if (string.enabled) {
        return this.addTypeOfPanel(string);
      }
      string.types = [];
    },
    panelPowerAndCode(panelName) {
      const panel = this.lists.fvPanels.find(panel => panel.model === panelName);
      return panel ? `${panel.power} Wp; ${panel.code}` : '';
    },
    async onSubmit() {
      try {
        const url = `/sledovaniZakazek/savePVSolForm`;
        const {data} = await axios.post(url, { data: this.contractData });
        this.$snotify[data.status](data.data.message);
        if (data.status === 'success') {
          this.$emit('form-saved')
          this.$emit('close-modal');
        }
      } catch (e) {
        console.error('saveCheckPVSolForm', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getInitData() {
      try {
        const dataConst = await this.getConstantsFromServer(
            [ 'INVERTER_MODELS', 'INVERTERS_FULL', 'BATTERY_CAPACITY', 'FV_PANELS_FULL', 'FV_PANELS_MODELS','ROOFS',
            'NUMBER_PHASES', 'DIRECTIONS' ]
        ) || {};
        this.lists.inverterType = dataConst.INVERTER_MODELS;
        this.lists.inverters = dataConst.INVERTERS_FULL;
        this.lists.batteryCapacity = dataConst.BATTERY_CAPACITY;
        this.lists.fvPanels = dataConst.FV_PANELS_FULL;
        this.lists.fvPanelModel = dataConst.FV_PANELS_MODELS;
        this.lists.roofs = dataConst.ROOFS;
        this.lists.numPhases = dataConst.NUMBER_PHASES;
        this.lists.directions = dataConst.DIRECTIONS;
      } catch (e) {
        console.error('getInitData', e);
        throw new Error()
      }
    },

    // Validations
    validateWithDataFromList(key, isInput, listName) {
      const isEqual = this.lists[listName || key].includes(_.get(this.contractData, key));
      return isInput && !isEqual ? false : !isEqual;
    },
    validatePanelsFromList(valueForValidation, listName) {
      if (!valueForValidation) return;
      const isEqual = this.lists[listName].includes(valueForValidation);
      return !isEqual;
    },
    async getSavedData() {
      if (!this.id) {
        return;
      }

      const url = `/sledovaniZakazek/getKontrolaPVSolFormInfo`;
      const { data: {data} } = await axios.get(url, { params: { id: this.id } });
      const info = data?.info || {};
      const panels = _.cloneDeep(this.contractData.panels);
      this.contractData = {...this.contractData, ...info};
      this.contractData.panels = this.contractData?.panels?.length ? this.contractData?.panels : panels;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getInitData();
    await this.getSavedData();
    this.isLoading = false;
  },

}
</script>

<style scoped>
  .modalWindow {
    width: 970px;
  }
  label {
    padding-right: 10px;
    font-size: 15px;
  }
  .input-group-text {
    max-height: 26px;
    max-width: 15px;
    padding: 6px 2px;
    text-align: center;
    left: 32px;
    position: absolute;
    z-index: 10;
    border: 0;
    background: no-repeat;
  }
  .append {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .app1 > div > div.input-group-text {
    left: 23px;
  }
</style>
