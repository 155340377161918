<template>
  <div v-if="isHaveAccess" class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <div class="text-left button-line pl-1">
        <tab-buttons :tabName="tabName" :tabs="TABS" class="pb-4 text-left" @click="changeTab" />
      </div>

      <div class="text-left filter-line d-flex">
        <span class="filtry-text">Filtry:</span>
        <b-form-select
            name="filterTypes"
            v-model="filterType"
            :options="listFilterTypes"
            class="form-select form-select-sm d-inline-block w-230px text-black"
            size="sm"
            @change="changeFilterTypes"
        />

        <template v-if="isFilterTypeNazev || isFilterZip || isFilterPhone">
          <b-form-input
              id="searchString"
              type="text"
              size="sm"
              v-model.trim="filter.searchString"
              class="d-inline-block w-250px ml-3 text-black"
              :class="[filter.searchString ? 'input-filtered' : '']"
              placeholder="napište co hledáte..."
              v-debounce="searchByString"
          />
        </template>
        <template v-else-if="isFilterTypeObject">
          <b-form-select
            name="object"
            v-model="filter.object"
            :options="lists.object"
            class="d-inline-block w-170px ml-3 form-select form-select-sm"
            size="sm"
            @change="changeTypObjektu"
          >
            <template #first>
              <option value=""></option>
            </template>
          </b-form-select>
        </template>
        <template v-else-if="isFilterTypeKategory">
          <b-form-select
            name="kategory"
            v-model="filter.kategory"
            :options="lists.kategory"
            class="d-inline-block w-170px ml-3 form-select form-select-sm"
            size="sm"
            @change="changeTypKategory"
          >
            <template #first>
              <option value=""></option>
            </template>
          </b-form-select>
        </template>
        <template v-else-if="isFilterTypeOkresOP">
          <b-form-input
              list="okresOPList"
              name="okresOP"
              class="form-control form-control-sm w-250px ml-3 text-black"
              v-model="filter.okresOP"
              @change="changeOkresSelect"
          />
          <b-datalist
              id="okresOPList"
              :options="lists.okresy"
              autocomplete="off"
          />
        </template>
        <template v-else-if="isFilterTypeKraj">
          <b-form-select
              name="stav"
              v-model="krajOption"
              :options="KRAJ"
              class="d-inline-block w-250px ml-3 form-select form-select-sm"
              size="sm"
              @change="changeKrajSelect"
          >
            <template #first>
              <option value=""></option>
            </template>
          </b-form-select>
        </template>
        <template v-else-if="isFilterTypeOzUsers">
          <b-form-input
              list="obchodniZastupceList"
              name="obchodniZastupce"
              class="form-control form-control-sm w-250px ml-3 text-black"
              v-model="filter.obchodniZastupceName"
              @change="changeOzUsers"
          />
          <b-datalist
              id="obchodniZastupceList"
              :options="ozUsersNames"
              autocomplete="off"
          />
        </template>
        <template v-else-if="isPManagerKoordinator">
          <b-form-input
              list="managerKoordinatorList"
              name="managerKoordinator"
              class="form-control form-control-sm w-250px ml-3 text-black"
              v-model="filter.managerKoordinatorName"
              @change="changeManagerKoordinator"
          />
          <b-datalist
              id="managerKoordinatorList"
              :options="managerKoordinatorFioTitles"
              autocomplete="off"
          />
        </template>

        <b-icon-x-circle
            @click="clearFilter"
            class="pl-2 pt-1 cursor-pointer"
            :class="[(filter.searchString || filter.obchodniZastupceName || krajOption || filter.managerKoordinatorName) ? 'filtered' : '']"
            font-scale="1.8"
            title="Čištění filtru"
        />
        <span class="pl-5 pt-3px">
          [ zakázek: {{ totalItems }} ]
        </span>
      </div>

      <b-table
          sticky-header="calc(100vh - 14.5rem)"
          bordered
          responsive
          hover
          no-border-collapse
          class="sledovaniZakazekPage stickyTable"
          :items="contracts"
          :fields="headers"
          thead-tr-class="align-middle"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-direction="desc"
          no-local-sorting
          @sort-changed="changeOrder"
      >
        <template #cell(orderNumber)="{ item }" class="color1">
          <div class="d-flex justify-content-between text-left">
            <template v-if="isPostupZakazkyModalAllowed">
              <div>
                <b-icon-clipboard-plus
                    class="user-enabled-email cursor-pointer"
                    v-b-tooltip.hover="{
                        title: 'Kopírovat název',
                        delay: { show: 200, hide: 200 },
                        variant: 'secondary'
                      }"
                    @click="copyOP(item)"
                />
                <a
                    href="#"
                    class="pl-2"
                    @click.prevent="openPostupZakazky(item)"
                    title="Upravit postup zakázky"
                >{{ item.orderNumber }}</a>
              </div>
              <a
                  :href="`mailto:${item.personaEmail || ''}?subject=${item.orderNumber}`"
                  title="Poslat email zákazníkovi"
                  class="pl-2"
              >
                <b-icon-envelope-fill class="user-enabled-email"/>
              </a>
            </template>
            <template v-else>
              {{ item.orderNumber }}
            </template>
          </div>
        </template>

        <template #cell(remainDaysOfPanelLayouts)="{ item }">
          <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
            <a
                href="#"
                @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                :title="linkTitle"
                class="bold color-red"
            >{{ item.remainDaysOfPanelLayouts }}</a>
          </template>
          <template v-else class="bold color-red">
            {{ item.remainDaysOfPanelLayouts }}
          </template>
        </template>

        <template #cell(remainDaysOfApprovalDeploymentCustomer)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red "
             >{{ item.remainDaysOfApprovalDeploymentCustomer }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysOfApprovalDeploymentCustomer }}
           </template>
        </template>

        <template #cell(remainDaysPD)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysPD }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysPD }}
           </template>
        </template>

        <template #cell(remainDaysEH)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysEH }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysEH }}
           </template>
        </template>

        <template #cell(remainDaysSigningEH)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysSigningEH }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysSigningEH }}
           </template>
        </template>

        <template #cell(remainDaysReqConnectDistrib)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysReqConnectDistrib }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysReqConnectDistrib }}
           </template>
        </template>

        <template #cell(remainDaysSingContractDistrib)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysSingContractDistrib }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysSingContractDistrib }}
           </template>
        </template>

        <template #cell(remainDaysSendingSignedContractPD)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysSendingSignedContractPD }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysSendingSignedContractPD }}
           </template>
        </template>

        <template #cell(remainDaysNZU)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysNZU }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysNZU }}
           </template>
        </template>

        <template #cell(remainDaysApprovalDateNZU)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysApprovalDateNZU }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysApprovalDateNZU }}
           </template>
        </template>

        <template #cell(remainDaysImplemStart)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysImplemStart }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysImplemStart }}
           </template>
        </template>

        <template #cell(remainDaysImplemCompletion)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysImplemCompletion }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysImplemCompletion }}
           </template>
        </template>

        <template #cell(remainDaysSendingDSPS)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysSendingDSPS }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysSendingDSPS }}
           </template>
        </template>

        <template #cell(remainDaysCompletingDSPS)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysCompletingDSPS }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysCompletingDSPS }}
           </template>
        </template>

        <template #cell(remainDaysUpdatingEH)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysUpdatingEH }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysUpdatingEH }}
           </template>
        </template>

        <template #cell(remainDaysSubmittingElectricalReview)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysSubmittingElectricalReview }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysSubmittingElectricalReview }}
           </template>
        </template>

        <template #cell(remainDaysDeliveryElectricalInspection)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysDeliveryElectricalInspection }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysDeliveryElectricalInspection }}
           </template>
        </template>

        <template #cell(remainDaysSendingParallelConnection)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysSendingParallelConnection }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysSendingParallelConnection }}
           </template>
        </template>

        <template #cell(remainDaysConnection)="{ item }">
           <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
             <a
                 href="#"
                 @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                 :title="linkTitle"
                 class="bold color-red"
             >{{ item.remainDaysConnection }}</a>
           </template>
           <template v-else class="bold color-red">
             {{ item.remainDaysConnection }}
           </template>
        </template>

        <template #cell(remainDaysSendingReimbursement)="{ item }">
          <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
            <a
                href="#"
                @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                :title="linkTitle"
                class="bold color-red"
            >{{ item.remainDaysSendingReimbursement }}</a>
          </template>
          <template v-else class="bold color-red">
            {{ item.remainDaysSendingReimbursement }}
          </template>
        </template>

        <template #cell(remainDaysDotaceTerminationContract)="{ item }">
          <template v-if="isTerminyPlneniKrokuZakazkyModalAllowed">
            <a
                href="#"
                @click.prevent="openTerminyPlneniKrokuZakazky(item)"
                :title="linkTitle"
                class="bold color-red"
            >{{ item.remainDaysDotaceTerminationContract }}</a>
          </template>
          <template v-else class="bold color-red">
            {{ item.remainDaysDotaceTerminationContract }}
          </template>
        </template>

        <template #cell(SODdata)="{ item }" class="color1">
          <div class="text-center"
               v-if="$store.getters.isKontrolaPVSolModalAllowed"
          >
            <a @click.prevent="showCheckPVSol(item)" title="Otevřít 'Kontrola PV*Sol'" href="#">
              {{ getFormattedDateCheckDisabled(item.SODdata, item, 'SODdata') }} <br>
              <b-icon-pencil-square v-if="!item.SODdata"/>
            </a>
          </div>
          <span v-else>
            {{ getFormattedDateCheckDisabled(item.SODdata, item, 'SODdata') }}
          </span>
        </template>

        <template #cell(dateSigningEH)="{ item }" class="color1">
          <div>
            {{
              getFormattedDateCheckDisabled(item.dateSigningEH, item, 'dateSigningEH') || item.dateSigningEHParseComment
            }}
          </div>
        </template>

        <template #cell(processingDateEH)="{ item }" class="color1">
          <div>
            {{
              getFormattedDateCheckDisabled(item.processingDateEH, item, 'processingDateEH') || item.processingDateEHParseComment
            }}
          </div>
        </template>

        <template #cell(sendingDateNZU)="{ item }" class="color1">
          <div>
            {{
              getFormattedDateCheckDisabled(item.sendingDateNZU, item, 'sendingDateNZU') || item.sendingDateNZUParseComment
            }}
          </div>
        </template>

        <template #cell(approvalDateNZU)="{ item }" class="color1">
          <div>
            {{
              getFormattedDateCheckDisabled(item.approvalDateNZU, item, 'approvalDateNZU') || item.approvalDateNZUParseComment
            }}
          </div>
        </template>

        <template #cell(updateDateEH)="{ item }" class="color1">
          <div>
            {{
              getFormattedDateCheckDisabled(item.updateDateEH, item, 'updateDateEH') || item.updateDateEHParseComment
            }}
          </div>
        </template>

        <template #cell(sendingReimbursementDate)="{ item }" class="color1">
          <div>
            {{
              getFormattedDateCheckDisabled(item.sendingReimbursementDate, item, 'sendingReimbursementDate') || item.sendingReimbursementDateParseComment
            }}
          </div>
        </template>

        <template #cell(connectionDate)="{ item }" class="color1">
          <div>
            {{
              getFormattedDateCheckDisabled(item.connectionDate, item, 'connectionDate') || item.connectionDateParseComment
            }}
          </div>
        </template>

        <template #cell(obchodniZastupce)="{ item }" class="color1 bg-color-white">
          <div class="line-height-14 fs-11px">
            <template v-if="isTymZakazkyModalAllowed">
              <a
                  href="#"
                  @click.prevent="openTymZakazky(item)"
                  title="Upravit tým zakázky"
                  class="text-black-color"
                  v-html="getUsersNamesList(item)"
              ></a>
            </template>
            <template v-else>
              <span v-html="getUsersNamesList(item)"></span>
            </template>
          </div>
        </template>

        <template #cell(podepsanaDate)="{ item }">
          <div class="text-center">
            <b-icon-journal
                font-scale="1.3"
                variant="secondary"
                class="cursor-pointer"
                @click="showKartaModal(item)"
            />
          </div>
          <div class="fs-11px pt-1">
            {{ item.podepsanaDate ? `${getFormattedDate(item.podepsanaDate)}` : ''}}
          </div>
        </template>

        <template #head()="{field}">
          <span v-if="field.num" class="rowNumber">{{ field.num }}</span>
          <span v-if="field.isHTML" v-html="field.label"></span>
          <div v-else>{{ field.label }}</div>
        </template>
      </b-table>

      <div v-if="!contracts.length" class="pt-5">
        <h5 class="p-5 fs-23px">Žádné výsledky vyhledávání</h5>
      </div>

      <kontrola-pv-sol-form
          v-if="showKontrolaPvSolForm && $store.getters.isKontrolaPVSolModalAllowed"
          :id="getIdSelectedContract"
          @close-modal="showKontrolaPvSolForm = false"
          @form-saved="formSaved"
      />

      <karta-form
          v-if="showKartaForm"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showKartaForm"
          @close-modal="showKartaForm = false"
      />

      <postup-zakazky-form
          v-if="showPostupZakazkyModal && isPostupZakazkyModalAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showPostupZakazkyModal"
          @close-modal="closePostupZakazkyForm"
          @save-modal="savePostupZakazkyForm"
      />
      <terminy-plneni-kroku-zakazky
          v-if="showTerminyPlneniKrokuZakazky && isTerminyPlneniKrokuZakazkyModalAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showTerminyPlneniKrokuZakazky"
          @close-modal="closeTerminyPlneniKrokuZakazky"
          @save-modal="saveTerminyPlneniKrokuZakazky"
      />

      <tym-zakazky-form
          v-if="showTymZakazkyModal && isTymZakazkyModalAllowed"
          :id="getIdSelectedContract"
          :kodZakazky="selectedContract.kodZakazky"
          :show="showTymZakazkyModal"
          @close-modal="closeTymZakazkyForm"
          @save-modal="saveTymZakazkyForm"
      />
    </b-overlay>
  </div>
</template>

<script>
import KontrolaPvSolForm from "@/components/modals/KontrolaPvSolForm.vue";
import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";
import _ from "lodash";
import {
  FILTER_TYPES,
  LIST_SLED_ZAKAZEK_FILTER_TYPES,
  TABULKA_TYPE,
  KRAJ,
  PRIPAD_OBJECTS,
  PRIPAD_CATEGORIES
} from "@/constants/form.constants";
import KartaForm from "@/components/modals/KartaForm.vue";
import TabButtons from "@/components/TabButtons.vue";
import TerminyPlneniKrokuZakazky from "@/components/modals/TerminyPlneniKrokuZakazky";
import PostupZakazkyForm from "@/components/modals/PostupZakazkyForm";
import TymZakazkyForm from "@/components/modals/TymZakazkyForm";

const TABS = [
  {tabName: TABULKA_TYPE.TABULKA_2, link: '/sledovani-zakazek/tabulka-2', name: 'Firmy a veřejnoprávní subjekty', title: 'Firmy a veřejnoprávní subjekty'},
  {tabName: TABULKA_TYPE.TABULKA_1, link: '/sledovani-zakazek/tabulka-1', name: 'Bytové a rodinné domy', title: 'Bytové a rodinné domy'},
];

const defaultSortDirection = true;
const defaultSortBy = 'podepsanaDate';

export default {
  components: {TabButtons, KartaForm, KontrolaPvSolForm, PostupZakazkyForm, TerminyPlneniKrokuZakazky, TymZakazkyForm },
  props: {
    tabName: {type: String, required: true},
  },
  computed: {
    isPostupZakazkyModalAllowed() {
      return this.$store.getters.isPostupZakazkyModalAllowed;
    },
    isTerminyPlneniKrokuZakazkyModalAllowed() {
      return this.$store.getters.isTerminyPlneniKrokuZakazkyModalAllowed;
    },
    isTymZakazkyModalAllowed() {
      return this.$store.getters.isTymZakazkyModalAllowed;
    },
    getIdSelectedContract() {
      return this.selectedContract?._id;
    },
    isFilterTypeNazev() {
      return this.filterType === FILTER_TYPES.NAZEV_V_ADRESARE;
    },
    isFilterTypeOzUsers() {
      return [FILTER_TYPES.OZ_USERS_ACTIVE, FILTER_TYPES.OZ_USERS_DEACTIVATED].includes(this.filterType);
    },
    isFilterTypeKraj() {
      return this.filterType === FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU;
    },
    isPManagerKoordinator() {
      return this.filterType === FILTER_TYPES.MANAGER_KOORDINATOR;
    },
    isFilterTypeOkresOP() {
      return this.filterType === FILTER_TYPES.OKRES_OP;
    },
    isFilterZip() {
      return this.filterType === FILTER_TYPES.ZIP;
    },
    isFilterPhone() {
      return this.filterType === FILTER_TYPES.PHONE;
    },
    isFilterTypeObject() {
      return this.filterType === FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU;
    },
    isFilterTypeKategory() {
      return this.filterType === FILTER_TYPES.KATEGORIE_PRIPADU;
    },
    isHaveAccess() {
      return this.$store.getters.isSledovaniZakazekPageAllowed;
    },
  },
  mixins: [commonMixin],
  data() {
    const headVertical1 = 'rotate mw-40px text-nowrap v-al-bot p-12-0 text-center font-weight-normal fs-14px';
    return {
      TABS,
      KRAJ,
      isLoading: false,
      contracts: [],
      showModal: false,
      showKontrolaPvSolForm: false,
      showKartaForm: false,
      selectedContract: null,
      totalItems: 0,
      sortBy: defaultSortBy,
      sortDesc: true,
      defaultSortBy,
      defaultSortDirection,
      filter: {
        name: null,
        searchString: null,
        obchodniZastupceName: null,
        managerKoordinatorName: null,
        managerKoordinatorId: null,
        okresOP: null,
        object: null,
        kategory: null,
        sortBy: null,
        sortDirection: defaultSortDirection,
      },
      type: TABULKA_TYPE.TABULKA_1,
      headers: [
        { label: 'Karta zakázky<br><span class="font-weight-normal fs-11px">(datum podpisu smlouvy)</span>', key: 'podepsanaDate', stickyColumn: true, variant: 'color-1', isHTML: true, sortable: true },
        { label: 'Postup zakázky<br><span class="font-weight-normal">(název adresáře v zakázkách)</span>', key: 'orderNumber', num: 1, stickyColumn: true, variant: 'color-1', class: 'mw-400px stickyColumn', isHTML: true, sortable: true },
        { label: 'Tým zakázky<br><span class="font-weight-normal fs-11px">(OZ, PM, PK, PO)</span>', key: 'obchodniZastupce', num: 2, variant: 'color-1', class: 'mw-150px', isHTML: true },
        { label: 'PV*Sol (datum kontroly)', key: 'SODdata', num: 3, variant: 'color-1', class: 'mw-100px', sortable: true },
        { label: 'Dotace', key: 'dotace', num: 4, variant: 'color-1', sortable: true },
        { label: 'AIS SFŽP', key: 'aisSFZP',num: 5, variant: 'color-1', sortable: true },
        { label: 'Souhlas CHKO nebo památkářů', key: 'souhlasCHKODate', num: 6, variant: 'color-1', class: 'mw-100px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Počet dnů do vypršení dotace', key: 'daysBeforeDotaceExp', num: 7, variant: 'color-2', sortable: true },
        { label: 'Termín vypršení dotace', key: 'dateDotaceExp', num: 8, variant: 'color-2', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín zaplacení 1. ZF', key: 'paymentDeadline1ZF', num: 9, variant: 'color-3', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín odeslání rozmístění panelů', key: 'deadlineForSubmittingPanelLayouts', num: 10, variant: 'color-4', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysOfPanelLayouts', variant: 'color-4', num: 11, class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.panelLayouts', num: 12, variant: 'color-4', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín schválení rozmístění zákazníkem', key: 'deadlineForApprovalDeploymentCustomer', num: 13, variant: 'color-3', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysOfApprovalDeploymentCustomer', num: 14, variant: 'color-3', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.approvalDeploymentCustomer', num: 15, variant: 'color-3', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín zpracování kompletu PD', key: 'completionDatePD', num: 16, variant: 'color-4', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysPD', num: 17, variant: 'color-4', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.PD', num: 18, variant: 'color-4', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín zpracování EH', key: 'processingDateEH', num: 19, variant: 'color-5', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysEH', num: 20, variant: 'color-5', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.EH', num: 21, variant: 'color-5', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Termín podpisu EH', key: 'dateSigningEH', num: 22, variant: 'color-5', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysSigningEH', num: 23, variant: 'color-5', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.signingEH', num: 24, variant: 'color-5', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Žádost o smlouvu s distributorem', key: 'reqConnectDistrib', num: 25, variant: 'color-6', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysReqConnectDistrib', num: 26, variant: 'color-6', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.reqConnectDistrib', num: 27, variant: 'color-6', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Podpis smlouvy s distributorem', key: 'dataSingContractDistrib', num: 28, variant: 'color-6', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysSingContractDistrib', num: 29, variant: 'color-6', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.singContractDistrib', num: 30, variant: 'color-6', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Odeslání podepsané smlouvy s distributorem', key: 'sendingSignedContractPD', num: 31, variant: 'color-7', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Distributor', key: 'distributor', num: 32, variant: 'color-7', sortable: true },
        { label: 'Termín vypršení smlouvy', key: 'contractExpDate', num: 33, variant: 'color-7', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Číslo smlouvy s distributorem', key: 'distribContractNumber', num: 34, variant: 'color-7', sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysSendingSignedContractPD', num: 35, variant: 'color-7', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.sendingSignedContractPD', num: 36, variant: 'color-7', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín odeslání žádosti na NZÚ', key: 'sendingDateNZU', num: 37, variant: 'color-8', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysNZU', num: 38, variant: 'color-8', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.NZU', variant: 'color-8', class: 'rotate deadline row-color-red mw-90px', num: 39, formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Termín schválení NZÚ', key: 'approvalDateNZU', num: 40, variant: 'color-8', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysApprovalDateNZU', num: 41, variant: 'color-8', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysApprovalDateNZU', num: 42, variant: 'color-8', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín zahájení realizace', key: 'implemStartDate', num: 43, variant: 'color-9', class: 'mw-100px', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysImplemStart', num: 44, variant: 'color-9', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysImplemStart', num: 45, variant: 'color-9', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Termín dokončení realizace (protokol)', key: 'implemCompletionDate', num: 46, variant: 'color-9', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysImplemCompletion', num: 47, variant: 'color-9', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysImplemCompletion', num: 48, variant: 'color-9', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Termín odeslání podkladů k DSPS', key: 'sendingDateDSPS', num: 49, variant: 'color-9', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysSendingDSPS', num: 50, variant: 'color-9', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysSendingDSPS', num: 51, variant: 'color-9', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín zpracování DSPS', key: 'completingDateDSPS', num: 52, variant: 'color-4', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysCompletingDSPS', num: 53, variant: 'color-4', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysCompletingDSPS', num: 54, variant: 'color-4', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín aktualizace EH', key: 'updateDateEH', num: 55, variant: 'color-4', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysUpdatingEH', num: 56, variant: 'color-4', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysUpdatingEH', num: 57, variant: 'color-4', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Termín zadání revize elektro', key: 'submittingElectricalReviewDate', num: 58, variant: 'color-10', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysSubmittingElectricalReview', num: 59, variant: 'color-10', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysSubmittingElectricalReview', num: 60, variant: 'color-10', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Termín dodání revize elektro', key: 'deliveryElectricalInspectionDate', num: 61, variant: 'color-10', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysDeliveryElectricalInspection', num: 62, variant: 'color-10', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysDeliveryElectricalInspection', num: 63, variant: 'color-10', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Odeslání žádosti o paralelní připojení', key: 'sendingParallelConnectionDate', num: 64, variant: 'color-7', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysSendingParallelConnection', num: 65, variant: 'color-7', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysSendingParallelConnection', num: 66, variant: 'color-7', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Termín připojení', key: 'connectionDate', num: 67, variant: 'color-7', formatter: (cur, key, item) => this.getFormattedDateCheckDisabled(cur, item, key), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysConnection', num: 68, variant: 'color-7', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysConnection', num: 69, variant: 'color-7', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },

        { label: 'Poznámka k dokončení zakázky (nedostačující dokumenty, data atd.)', key: 'noteCompletionContractNoData', num: 70, variant: 'color-4', class: 'mw-300px' },

        { label: 'Termín odeslání žádosti o proplacení', key: 'sendingReimbursementDate', num: 71, variant: 'color-11', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysSendingReimbursement', num: 72, variant: 'color-11', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysSendingReimbursement', num: 73, variant: 'color-11', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Proplacení dotace Ukončení zakázky', key: 'dotaceTerminationContractDate', num: 74, variant: 'color-11', formatter: cur => this.getFormattedDate(cur), sortable: true },
        { label: 'Zbývající dni', key: 'remainDaysDotaceTerminationContract', num: 75, variant: 'color-11', class: headVertical1 },
        { label: 'Deadline', key: 'deadlines.daysDotaceTerminationContract', num: 76, variant: 'color-11', class: 'rotate deadline row-color-red mw-90px', formatter: cur => this.getFormattedDate(cur), sortable: true  },
      ],
      limit: 30,
      offset: 0,
      lastScrollTop: 0,
      listFilterTypes: this.getFilteredFilterTypes(),
      filterType: FILTER_TYPES.NAZEV_V_ADRESARE,
      ozUsers: [],
      ozUsersNames: [],
      managerKoordinatorFioTitles: [],
      managerKoordinatorFull: [],
      activeOzUsersNames: [],
      activeOzUsersInfo: [],
      deactivatedOzUsersNames: [],
      deactivatedOzUsersInfo: {},
      lists: {
        okresy: [],
        object: PRIPAD_OBJECTS,
        kategory: PRIPAD_CATEGORIES,
      },
      krajOption: null,
      abortController: new AbortController(),
      abortControllerCount: new AbortController(),
      showPostupZakazkyModal: false,
      showTerminyPlneniKrokuZakazky: false,
      linkTitle: "Upravit 'Termíny plnění kroků zakázky'",
      showTymZakazkyModal: false
    }
  },
  methods: {
    openPostupZakazky(item) {
      this.selectedContract = item;
      this.showPostupZakazkyModal = true;
    },
    openTerminyPlneniKrokuZakazky(item) {
      this.selectedContract = item;
      this.showTerminyPlneniKrokuZakazky = true;
    },
    closePostupZakazkyForm() {
      this.showPostupZakazkyModal = false;
    },
    closeTerminyPlneniKrokuZakazky() {
      this.showTerminyPlneniKrokuZakazky = false;
    },
    async savePostupZakazkyForm() {
      this.closePostupZakazkyForm();
      await this.formSaved();
    },
    async saveTerminyPlneniKrokuZakazky() {
      this.closeTerminyPlneniKrokuZakazky();
      await this.formSaved();
    },
    openTymZakazky(item) {
      this.selectedContract = item;
      this.showTymZakazkyModal = true;
    },
    closeTymZakazkyForm() {
      this.showTymZakazkyModal = false;
    },
    async saveTymZakazkyForm() {
      this.closeTymZakazkyForm();
      await this.formSaved();
    },
    async getListSledovaniZakazek(/*noCountQuery*/) {
      try {
        const filterByUserId = this.getSelectedOzUserId();
        if (!filterByUserId && this.filter.obchodniZastupceName) return;

        this.abortController.abort();
        this.abortController = new AbortController();

        this.isLoading = true;
        const url = `/contract/getListSledovaniZakazek`;
        this.filter.managerKoordinatorId = this.getSelectedManagerKoordinatorId();
        const {data: {data}} = await axios.post(url, {
              offset: this.offset,
              limit: this.limit,
              filter: this.filter,
              filterByUserId: this.getSelectedOzUserId(),
              type: this.tabName
            },
            {signal: this.abortController.signal}
        );

        if (!this.offset) {
          this.contracts = data;
        } else {
          this.contracts.push(...data);
        }
        this.offset = this.offset > this.contracts.length ? this.offset : this.limit + this.offset;
        this.loading = false;
        this.isLoading = false;
      } catch (e) {
        if (!e?.response?.data?.message) {
          // console.error('Operation canceled');
        } else {
          console.log('getContracts', e.response?.data);
          this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
          this.loading = false;
          this.isLoading = false;
        }
      }
    },
    async getSledovaniZakazekCount(noCountQuery) {
      try {
        if (noCountQuery) return;

        const filterByUserId = this.getSelectedOzUserId();
        if (!filterByUserId && this.filter.obchodniZastupceName) return;

        this.abortControllerCount.abort();
        this.abortControllerCount = new AbortController();

        const url = `/contract/getSledovaniZakazekCount`;
        this.filter.managerKoordinatorId = this.getSelectedManagerKoordinatorId();
        const {data: {data}} = await axios.post(url, {
              filter: this.filter,
              filterByUserId,
              type: this.tabName
            },
            {signal: this.abortController.signal}
        );
        this.totalItems = data?.total || 0;
      } catch (e) {
        console.error('getObchodniPripadyCount', e);
        throw new Error()
      }
    },
    async getInfo(noCountQuery = false) {
      await Promise.all([
        this.getListSledovaniZakazek(noCountQuery),
        this.getSledovaniZakazekCount(noCountQuery),
      ]);
    },
    async getOzUsersGroupsForUser() {
      try {
        const url = `/user/getOzUsersGroupsForUser`;
        const {data} = await axios.get(url, {});
        this.activeOzUsersNames = data?.data?.activeOzUsersNamesFioTitles || [];
        this.activeOzUsersInfo = data?.data?.activeOzUsersInfo || [];
        this.deactivatedOzUsersNames = data?.data?.deactivatedOzUsersNamesFioTitles || [];
        this.deactivatedOzUsersInfo = data?.data?.deactivatedOzUsersInfo || [];
      } catch (error) {
        console.log(error);
      }
    },
    async formSaved() {
      this.offset = 0;
      await this.getInfo();
    },
    showCheckPVSol(item) {
      this.selectedContract = item;
      this.showKontrolaPvSolForm = true;
    },
    async handleScroll() {
      const tableEl = this.$el.querySelector('.table-responsive');
      const currentScrollTop = tableEl.scrollTop;
      if (currentScrollTop > this.lastScrollTop && (currentScrollTop + tableEl.offsetHeight + 5 >= tableEl.scrollHeight) && !this.loading) {
        this.loading = true;
        await this.getInfo(true);
      }
      this.lastScrollTop = currentScrollTop;
    },
    async searchByString() {
      this.offset = 0;
      this.filter.name = this.filterType;
      await this.getInfo();
    },
    async changeOkresSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.OKRES_OP);
    },
    async clearFilter() {
      this.offset = 0;
      this.filter.name = this.filterType;
      this.filter.searchString = null;
      this.filter.obchodniZastupceName = null;
      this.filter.managerKoordinatorName = null;
      this.filter.okresOP = null;
      this.filter.object = null;
      this.filter.kategory = null;
      this.krajOption = null;
      this.resetScrollTop();
      await this.getInfo();
    },
    getUsersNamesList(item) {
      return item.userNamesArray.join('<br>')
    },
    getFilteredFilterTypes() {
      return this.getFilteredByOzUsersFilterTypes(LIST_SLED_ZAKAZEK_FILTER_TYPES)
    },
    async changeFilterTypes() {
      switch (this.filterType) {
        case FILTER_TYPES.OZ_USERS_ACTIVE:
          this.ozUsers = this.activeOzUsersInfo;
          this.ozUsersNames = this.activeOzUsersNames;
          break;
        case FILTER_TYPES.OZ_USERS_DEACTIVATED:
          this.ozUsers = this.deactivatedOzUsersInfo;
          this.ozUsersNames = this.deactivatedOzUsersNames;
          break;
      }
      await this.clearFilter();
    },
    getSelectedOzUserId() {
      return _.first(this.ozUsers.filter(u => u.fullNameFioTitles === this.filter.obchodniZastupceName))?.id;
    },
    async changeSelectList(searchString, filterName) {
      this.offset = 0;
      this.filter.name = filterName;
      this.filter.searchString = searchString;
      this.resetScrollTop();
      await this.getInfo();
    },
    async changeKrajSelect(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU);
    },
    async changeTypObjektu(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU);
    },
    async changeTypKategory(searchString) {
      await this.changeSelectList(searchString, FILTER_TYPES.KATEGORIE_PRIPADU);
    },
    changeOzUsers() {
      this.resetScrollTop();
    },
    changeManagerKoordinator() {
      this.resetScrollTop();
    },
    async getManagersKoordinators() {
      const url = `/user/getManagersKoordinators`;
      const {data} = await axios.get(url, {});
      this.managerKoordinatorFioTitles = data?.data?.managersKoordinatorsNamesFioTitles || [];
      this.managerKoordinatorFull = data?.data?.managersKoordinatorsInfo || [];
    },
    getSelectedManagerKoordinatorId() {
      return _.first(this.managerKoordinatorFull.filter(u => u.fullNameFioTitles === this.filter.managerKoordinatorName))?.id;
    },
    showKartaModal(item) {
      this.selectedContract = item;
      this.showKartaForm = true;
    },
    async getOkresy() {
      try {
        this.lists.okresy = await this.getOkresyList();
      } catch (error) {
        console.log(error);
      }
    },
    async changeTab() {
      this.offset = 0;
      await this.getInfo();
    },
    copyOP(item) {
      this.copyToClipboard(item.orderNumber);
    },
    copyToClipboard(text) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$snotify['success']('Text byl zkopírován do schránky');
    },
    setDefaultSort() {
      this.sortBy = this.defaultSortBy;
      this.sortDesc = this.defaultSortDirection;
      this.filter.sortBy = this.defaultSortBy;
      this.filter.sortDirection = this.defaultSortDirection;
    },
    async changeOrder(ctx) {
      if (ctx.sortBy) {
        this.filter.sortBy = ctx.sortBy;
        this.filter.sortDirection = ctx.sortDesc;
      } else {
        this.setDefaultSort();
      }
      this.offset = 0;
      this.resetScrollTop();
      await this.getInfo(true);
    },
  },
  async mounted() {
    this.isLoading = true;
    this.filter.name = FILTER_TYPES.NAZEV_V_ADRESARE;
    await this.getManagersKoordinators();

    await Promise.all([
      this.getInfo(),
      this.getOzUsersGroupsForUser(),
      this.getOkresy(),
    ]);

    const table = this.$el?.querySelector('.table-responsive');
    if (table) {
      table.addEventListener('scroll', this.handleScroll);
    }
    this.isLoading = false;
  },
  beforeDestroy() {
    if (typeof this.$el === 'object' && this.$el !== null && 'querySelectorAll' in this.$el) {
      this.$el.querySelector('.table-responsive').removeEventListener('scroll', this.handleScroll);
    }
  },

}
</script>

<style scoped>
.rotate > div {
  transform: rotate(-90deg);
}
.deadline > div {
  font-weight: normal;
  font-size: 13px;
}
.bg-color-white {
  background-color: white;
}
.table-bordered th, .table-bordered td {
  border: 1px solid black !important;
}

</style>
