<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="postupZakazky-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100"> Postup zakázky č. {{ kodZakazky }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <b-form @submit.stop.prevent="savePostupZakazky">
          <b-form-group v-for="date in dates" :key="date.key">
            <div v-if="date.title" class="d-flex align-items-center pr-1">
              <div class="text-nowrap px-3 w-350px">
                <label :for="date.key + '-group'" class="pr-1" :class="date.bold ? 'bold' : ''">{{date.title}}</label>
              </div>
              <b-form-datepicker
                  :id="date.key + '-group'"
                  :name="date.key + '-group'"
                  v-model.trim="formData[date.key]"
                  class="form-control form-control-sm w-150px pr-1"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  placeholder=""
                  locale="cs"
                  @input="setDate(date.key)"
                  :disabled="disabledDates[date.key]"
              />
              <b-icon-x-circle
                  :id="date.key + '-group'"
                  class="cursor-pointer fs-16px ml-2 mr-2"
                  :class="[formData[date.key] ? 'filtered' : 'disabled']"
                  @click="clearDate(date.key)"
              />
              <b-form-checkbox
                  :name="date.key + '-group'"
                  v-model="disabledDates[date.key]"
                  @change="disableDate(date.key)"
              >
                <span class="mr-4">přeskočit</span>
              </b-form-checkbox>
              <div v-if="infoChangeHistory[date.key]" class="pl-2 max-w-390px text-nowrap color-grey mnogotochie h-24px">
                <span class="pr-2">|</span>
                <span>vyplněno {{ getChangerInfoString(infoChangeHistory[date.key]) }}</span>
              </div>
            </div>
            <hr v-else class="mt-2 mb-2">
          </b-form-group>

          <div class="d-flex justify-content-center w-100>">
            <b-button
                type="submit"
                class="mt-4 mb-2"
                :class="[isSaveBtnEnabled ? '' : 'disabled']"
                variant="primary"
                :disabled="!isSaveBtnEnabled"
            >
              Uložit data
            </b-button>
          </div>
        </b-form>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import Axios from "axios";
import _ from "lodash"
import {mapGetters} from "vuex";
import moment from "moment";


const date = {
  SODdata: { key: 'SODdata', title: 'PV*Sol (datum kontroly)', bold: true },
  deadlineForSubmittingPanelLayouts: { key: 'deadlineForSubmittingPanelLayouts', title: 'Termín odeslání rozmístění panelů', bold: false },
  deadlineForApprovalDeploymentCustomer: { key: 'deadlineForApprovalDeploymentCustomer', title: 'Termín schválení rozmístění zákazníkem', bold: false },

  completionDatePD: { key: 'completionDatePD', title: 'Termín zpracování kompletu PD', bold: true },
  souhlasCHKODate: { key: 'souhlasCHKODate', title: 'Souhlas CHKO nebo památkářů', bold: false },
  processingDateEH: { key: 'processingDateEH', title: 'Termín zpracování EH', bold: false },
  dateSigningEH: { key: 'dateSigningEH', title: 'Termín podpisu EH', bold: false },

  reqConnectDistrib: { key: 'reqConnectDistrib', title: 'Žádost o připojení distributoru', bold: true },
  dataSingContractDistrib: { key: 'dataSingContractDistrib', title: 'Podpis smlouvy s distributorem', bold: false },
  sendingSignedContractPD: { key: 'sendingSignedContractPD', title: 'Odeslání podepsané smlouvy s distributorem', bold: false },

  sendingDateNZU: { key: 'sendingDateNZU', title: 'Termín odeslání žádosti na NZÚ', bold: true },
  approvalDateNZU: { key: 'approvalDateNZU', title: 'Termín schválení NZÚ', bold: false },

  implemStartDate: { key: 'implemStartDate', title: 'Termín zahájení realizace', bold: true },
  implemCompletionDate: { key: 'implemCompletionDate', title: 'Termín dokončení realizace (protokol)', bold: false },
  sendingDateDSPS: { key: 'sendingDateDSPS', title: 'Termín odeslání podkladů k DSPS', bold: false },
  completingDateDSPS: { key: 'completingDateDSPS', title: 'Termín zpracování DSPS', bold: false },
  updateDateEH: { key: 'updateDateEH', title: 'Termín aktualizace EH', bold: false },

  submittingElectricalReviewDate: { key: 'submittingElectricalReviewDate', title: 'Termín zadání revize elektro', bold: true },
  deliveryElectricalInspectionDate: { key: 'deliveryElectricalInspectionDate', title: 'Termín dodání revize elektro', bold: false },
  sendingParallelConnectionDate: { key: 'sendingParallelConnectionDate', title: 'Odeslání žádosti o paralelní připojení', bold: false },
  connectionDate: { key: 'connectionDate', title: 'Termín připojení', bold: false },
};

const dates = [
    date.SODdata, date.deadlineForSubmittingPanelLayouts, date.deadlineForApprovalDeploymentCustomer, { key: 'hr1', title: '' },
    date.completionDatePD, date.souhlasCHKODate, date.processingDateEH, date.dateSigningEH, { key: 'hr2', title: '' },
    date.reqConnectDistrib, date.dataSingContractDistrib, date.sendingSignedContractPD, { key: 'hr3', title: '' },
    date.sendingDateNZU, date.approvalDateNZU, { key: 'hr4', title: '' },
    date.implemStartDate, date.implemCompletionDate, date.sendingDateDSPS, date.completingDateDSPS, date.updateDateEH, { key: 'hr5', title: '' },
    date.submittingElectricalReviewDate, date.deliveryElectricalInspectionDate, date.sendingParallelConnectionDate, date.connectionDate,
]

const formData = {};
const disabledDates = {};
Object.keys(date).forEach(key => {
  formData[key] = null;
  disabledDates[key] = false;
})

export default {
  name: "PostupZakazkyForm",
  computed: {
    isHaveAccess() {
      return this.$store.getters.isPostupZakazkyModalAllowed;
    },
    ...mapGetters(['getUserNamesAndTitles' ]),
  },
  props: {
    id: { type: String },
    kodZakazky: { type: String },
    show: { type: Boolean },
  },
  data() {
    return {
      dates,
      showLocal: true,
      isLoading: false,
      isSaveBtnEnabled: true,
      initialFormData: {},
      initialDisabledForEdit: {},
      updatedDatesSession: {},
      formData: _.cloneDeep(formData),
      infoChangeHistory: _.cloneDeep(formData),
      disabledDates: _.cloneDeep(disabledDates),
      disabledDatesSession: {},
    }
  },
  methods: {
    async getPostupZakazkyFormData() {
      try {
        this.isLoading = true;
        const url = `/contract/getPostupZakazkyFormData`;
        const { data: {data} } = await Axios.post(url, { id: this.id });

        this.initialFormData = _.cloneDeep(data);
        this.initialFormData.disabledForEdit = this.initialFormData.disabledForEdit || {};
        this.formData = _.cloneDeep(data);
        if (data.disabledForEdit) {
          this.disabledDates = { ...this.disabledDates, ...data.disabledForEdit };
        }
        this.infoChangeHistory = {...this.infoChangeHistory, ..._.get(data, 'infoChangeHistory.postupZakazkyInfo', {})};
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },

    getEditorNameAndDateEdit() {
      return {
          date: this.getDateOfChangesInfo(),
          name: this.$store.getters.getUserNamesAndTitles,
      };
    },

    async savePostupZakazky() {
      this.isLoading = true;
      const updatedFields = await this.getUpdatedFields();
      const url = `/contract/savePostupZakazky`;
      const {data} = await Axios.post(url, { data: {updatedFields, changerNameAndDate: this.infoChangeHistory, disabledDates: this.disabledDates}, id: this.id});
      if (data.status === 'success') {
        this.$snotify[data.status](data.data.message);
        this.$emit('save-modal');
      }
      this.isLoading = false;
    },

    async getUpdatedFields() {
      const changes = {};
      Object.keys(this.formData).forEach(key => {
        if (this.initialFormData[key] !== this.formData[key]) {
          changes[key] = this.formData[key];
        }
        delete changes['infoChangeHistory'];
        delete changes['disabledDates'];
      });
      return changes;
    },

    getDateOfChangesInfo() {
      return moment().format('DD.MM.YYYY');
    },

    async setDate(fieldName) {
      this.infoChangeHistory[fieldName] = this.getEditorNameAndDateEdit();
      this.updatedDatesSession[fieldName] = this.formData[fieldName];
    },

    clearDate(fieldName) {
      this.formData[fieldName] = null;
      if (!this.initialFormData[fieldName]) {
        _.omit(this.updatedDatesSession, fieldName);
        this.infoChangeHistory[fieldName] = null;
      } else {
        this.infoChangeHistory[fieldName] = this.getEditorNameAndDateEdit();
      }
    },

    disableDate(fieldName) {
      if (!_.isBoolean(this.disabledDatesSession[fieldName])) {
        this.formData[fieldName] = null;
        this.infoChangeHistory[fieldName] = this.getEditorNameAndDateEdit();
        this.disabledDatesSession[fieldName] = true;
      } else {
        delete this.disabledDatesSession[fieldName];
        if (this.initialFormData[fieldName] !== this.formData[fieldName]) {
          this.formData[fieldName] = _.cloneDeep(this.initialFormData[fieldName]);
          const oldEditorInfo = _.get(this.initialFormData, `infoChangeHistory.postupZakazkyInfo[${fieldName}]`, null);
          this.infoChangeHistory[fieldName] = oldEditorInfo || this.getEditorNameAndDateEdit(oldEditorInfo);
        } else {
          this.infoChangeHistory[fieldName] = null;
        }
      }
    },

    getChangerInfoString(editorInfo = null) {
      const editor = editorInfo || this.getEditorNameAndDateEdit();
      return `${editor.date} (${editor.name})`
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getPostupZakazkyFormData();
    this.isLoading = false;
  }
}
</script>

<style scoped>
label {
  padding-right: 10px;
  font-size: 15px;
}
</style>