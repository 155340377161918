<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="terminyPlneniKrokuZakazky-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100"> Termíny plnění kroků zakázky č. {{ kodZakazky }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(saveTerminyPlneniKrokuZakazky)">
          <b-form-group v-for="den in dni" :key="den.key">
            <div v-if="den.title" class="d-flex align-items-center pr-1">
              <div class="text-nowrap px-3 w-350px">
                <label :for="den.key + '-group'" class="pr-1" :class="den.bold ? 'bold' : ''">{{den.title}}</label>
              </div>
              <ValidationProvider rules="required|integer|between:1,999" :name="'\'' + den.title + '\''" mode="lazy" v-slot="{ classes, errors }">
                <b-form-input
                    :id="den.key + '-group'"
                    :name="den.key + '-group'"
                    v-model.trim="formData[den.key]"
                    class="form-control form-control-sm w-65px pr-10"
                    locale="cs"
                    type="text"
                    :title="errors[0]"
                    :state="getState(classes)"
                    @change="dayChange(den.key)"
                />
              </ValidationProvider>
              <span class="ml-2">dní</span>
              <div v-if="infoChangeHistory[den.key]" class="pl-4 max-w-290px text-nowrap color-grey mnogotochie h-24px pt-1px">
                <span class="pr-2">|</span>
                <span :id="den.key">změněno {{ getChangerInfoString(infoChangeHistory[den.key]) }}</span>
                <b-tooltip :target="den.key" variant="secondary">změněno {{ getChangerInfoString(infoChangeHistory[den.key]) }}</b-tooltip>
              </div>
            </div>
            <hr v-else class="mt-2 mb-2">
          </b-form-group>

          <div class="d-flex justify-content-center w-100>">
            <b-button
                type="submit"
                class="mt-4 mb-2"
                :class="[isSaveBtnEnabled ? '' : 'disabled']"
                variant="primary"
                :disabled="!isSaveBtnEnabled"
            >
              Uložit data
            </b-button>
          </div>
        </b-form>
        </ValidationObserver>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import Axios from "axios";
import _ from "lodash";
import { TERMINY_PLNENI_KROKU_ZAKAZKY } from "@/constants/form.constants"
import commonMixin from "@/mixins/common.mixin.ts";
import moment from "moment";

const date = {
  dniProOdeslaniRozmisteniPanelu: { key: 'dniProOdeslaniRozmisteniPanelu', title: 'Termín odeslání rozmístění panelů', bold: true},
  dniProSchvaleniRozmisteniZakaznikem: { key: 'dniProSchvaleniRozmisteniZakaznikem', title: 'Termín schválení rozmístění zákazníkem', bold: false},

  dniProZpracovaniKompletuPD: { key: 'dniProZpracovaniKompletuPD', title: 'Termín zpracování kompletu PD', bold: true},
  dniProZpracovaniEH: { key: 'dniProZpracovaniEH', title: 'Termín zpracování EH', bold: false},
  dniProPodpisuEH: { key: 'dniProPodpisuEH', title: 'Termín podpisu EH', bold: false},

  dniProZadostPripojeniDistr: { key: 'dniProZadostPripojeniDistr', title: 'Žádost o smlouvu s distributorem', bold: true},
  dniProPodpisSmlouvyDistr: { key: 'dniProPodpisSmlouvyDistr', title: 'Podpis smlouvy s distributorem', bold: false},
  dniProOdeslaniPodepsaneSmlouvyDistr: { key: 'dniProOdeslaniPodepsaneSmlouvyDistr', title: 'Odeslání podepsané smlouvy s distributorem', bold: false},

  dniProOdeslaniZadostiNaNZU: { key: 'dniProOdeslaniZadostiNaNZU', title: 'Termín odeslání žádosti na NZÚ', bold: true},
  dniProSchvaleniNZU: { key: 'dniProSchvaleniNZU', title: 'Termín schválení NZÚ', bold: false},

  dniProZahajeniRealizace: { key: 'dniProZahajeniRealizace', title: 'Termín zahájení realizace', bold: true},
  dniProDokonceniRealizaceProtokol: { key: 'dniProDokonceniRealizaceProtokol', title: 'Termín dokončení realizace (protokol)', bold: false},
  dniProOdeslaniPodkladuDSPS: { key: 'dniProOdeslaniPodkladuDSPS', title: 'Termín odeslání podkladů k DSPS', bold: false},
  dniProZpracovaniDSPS: { key: 'dniProZpracovaniDSPS', title: 'Termín zpracování DSPS', bold: false},
  dniProAktualizaceEH: { key: 'dniProAktualizaceEH', title: 'Termín aktualizace EH', bold: false},

  dniProZadaniRevizeElektro: { key: 'dniProZadaniRevizeElektro', title: 'Termín zadání revize elektro', bold: true},
  dniProDodaniRevizeElektro: { key: 'dniProDodaniRevizeElektro', title: 'Termín dodání revize elektro', bold: false},
  dniProOdeslaniZadostiParalelniPripojeni: { key: 'dniProOdeslaniZadostiParalelniPripojeni', title: 'Odeslání žádosti o paralelní připojení', bold: false},
  dniProPripojeniProtokolPPP: { key: 'dniProPripojeniProtokolPPP', title: 'Termín připojení (protokol o PPP)', bold: false},

  dniProOdeslaniZadostiProplaceni: { key: 'dniProOdeslaniZadostiProplaceni', title: 'Termín odeslání žádosti o proplacení', bold: true},
  dniProProplaceniDotace: { key: 'dniProProplaceniDotace', title: 'Proplacení dotace. Ukončení zakázky', bold: false},
}
const dni = [
  date.dniProOdeslaniRozmisteniPanelu, date.dniProSchvaleniRozmisteniZakaznikem, { key: 'hr1', title: '' },
  date.dniProZpracovaniKompletuPD, date.dniProZpracovaniEH, date.dniProPodpisuEH, { key: 'hr2', title: '' },
  date.dniProZadostPripojeniDistr, date.dniProPodpisSmlouvyDistr, date.dniProOdeslaniPodepsaneSmlouvyDistr, { key: 'hr3', title: '' },
  date.dniProOdeslaniZadostiNaNZU, date.dniProSchvaleniNZU, { key: 'hr4', title: '' },
  date.dniProZahajeniRealizace, date.dniProDokonceniRealizaceProtokol, date.dniProOdeslaniPodkladuDSPS, date.dniProZpracovaniDSPS, date.dniProAktualizaceEH, { key: 'hr5', title: '' },
  date.dniProZadaniRevizeElektro, date.dniProDodaniRevizeElektro, date.dniProOdeslaniZadostiParalelniPripojeni, date.dniProPripojeniProtokolPPP, { key: 'hr6', title: '' },
  date.dniProOdeslaniZadostiProplaceni, date.dniProProplaceniDotace
]

export default {
  name: "TerminyPlneniKrokuZakazky.vue",
  mixins: [commonMixin],
  computed: {
    isHaveAccess() {
      return this.$store.getters.isTerminyPlneniKrokuZakazkyModalAllowed;
    },
    ...mapGetters(['getUserNamesAndTitles' ]),
  },
  props: {
    id: { type: String },
    kodZakazky: { type: String },
    show: { type: Boolean },
    zbyvajiciDny: {},
  },
  data() {
    return {
      dni,
      showLocal: true,
      isLoading: false,
      isSaveBtnEnabled: true,
      updatedDatesSession: {},
      formData: TERMINY_PLNENI_KROKU_ZAKAZKY,
      initialFormData: {},
      infoChangeHistory: {},
      disabledDatesSession: {},
    }
  },
  methods: {
    async getTerminyPlneniKrokuZakazky() {
      try {
        this.isLoading = true;
        const url = `/contract/getTerminyPlneniKrokuZakazky`;
        const { data: {data} } = await Axios.post(url, { id: this.id });
        this.initialFormData = {...this.formData, ..._.cloneDeep(data)};
        this.formData = {...this.formData, ..._.cloneDeep(data.zbyvajiciDni)};
        this.infoChangeHistory = {...this.infoChangeHistory, ..._.get(data, 'infoChangeHistory.zbyvajiciDni', {})};
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },

    async saveTerminyPlneniKrokuZakazky() {
      this.isLoading = true;
      const updatedFields = await this.getUpdatedFields();
      const zbyvajiciDni = {...updatedFields};
      const url = `/contract/saveTerminyPlneniKrokuZakazky`;
      const {data} = await Axios.post(url, { data: {zbyvajiciDni, changerNameAndDate: this.infoChangeHistory}, id: this.id});
      if (data.status === 'success') {
        this.$snotify[data.status](data.data.message);
        this.$emit('save-modal');
      }
      this.isLoading = false;
    },

    async getUpdatedFields() {
      const changes = {};
      Object.keys(this.formData).forEach(key => {
        if (this.initialFormData[key] !== this.formData[key]) {
          changes[key] = this.formData[key];
        }
        delete changes['infoChangeHistory'];
      });
      return changes;
    },

    getChangerInfoString(editorInfo = null) {
      const editor = editorInfo || this.getEditorNameAndDateEdit();
      return `${editor.date} (${editor.name})`
    },

    dayChange(fieldName) {
      this.infoChangeHistory[fieldName] = this.getEditorNameAndDateEdit()
    },

    getEditorNameAndDateEdit() {
      return {
        date: this.getDateOfChangesInfo(),
        name: this.$store.getters.getUserNamesAndTitles,
      };
    },

    getDateOfChangesInfo() {
      return moment().format('DD.MM.YYYY');
    },

  },
  async mounted() {
    this.isLoading = true;
    await this.getTerminyPlneniKrokuZakazky()
    this.isLoading = false;
  }
}
</script>

<style scoped>

</style>